<section class="subscribe">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="subscribe-cont">
          <h2></h2>
          <p>
            Stay up to date with the latest news and insights from AS3ICS to all
            things tech, blockchain, AI related, and whatever else happens to
            come across our minds.
          </p>
          <div class="subscribe-form-cont">
            <form>
              <input
                type="text"
                class="subscribe-txt"
                placeholder="Subscribe Your Email"
              />
              <input
                type="submit"
                class="subscribebtn"
                value="Submit"
                (click)="onSubmit($event)"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
