<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img
        class="first-slide img-fluid"
        src="assets/images/inner-banner.png"
        alt="First slide"
      />
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp white">About</h2>
          <p class="breadcrumb-item white">
            <a href="#" style="color: white">Home</a>&nbsp; / About
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <img
          class="img-fluid abt-img"
          src="assets/images/blockchain.png"
          alt="about-3"
        />
      </div>
      <div class="col-lg-6">
        <div class="about-3-text">
          <h4>
            We constantaly watch the inovators and free thinkers emerging ub the
            blockchain, crypto, and AI technologies.
          </h4>
          <blockquote>
            We are at the beginning of what might be the start of a new era of
            technology, and you don't want to get left behind.
          </blockquote>
          <p>
            Blockchain and AI are fast emerging and developing technologies that
            not only impact many people and their careers, but is also changing
            and evolving every single day. We stay up to speed on all the latest
            news, rumors, features, and implications so that you can get all the
            information in one place.
            <b>See what AS3ICS can do for you today!</b>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-caption">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="about-caption">
          <h2>
            We will never take on a project that <br />
            we know we can not deliver on. That's our <br />
            promise and guarantee.
          </h2>
          <p>
            <a
              class="btn btn-md btn-primary"
              href="mailto:contact@as3ics.com"
              role="button"
              >Contact</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-accordian">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <a
                  class="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Where did we come from?
                </a>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                All of the employees and contributors at AS3ICS have a proven
                history of being leaders in their respective fields, whether it
                be programming some of the most safety-stringent and dangerous
                software in automobiles that could lead to serious injury or
                harm if it doesn't work 99.999999% of the time, or it is writing
                a script for a movie that won someone I know you know an Oscar.
                As I said, we are leaders, and while we each may have our
                special skills that we are best at we are all very well rounded
                individuals when it comes to the other un-technical abilities.
                For example both Kirk and Zach are avid musicians, and they have
                many late night jam sessions over FaceTime. And both of them are
                writing books.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <a
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How did we get here?
                </a>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                At AS3ICS we have all put in our 10,000 hours many years ago,
                and have spent so much time doing the things that we do for a
                living that we don't even realize that they're difficult
                anymore. For every good idea we've had there were probably 5 bad
                ones that we spent 10 times as much time on as the final project
                that was delivered. We are eccentric; we have a natural passion
                and drive to learn and understand these new technologies and how
                they can be used. We do what we do because we love it, and we
                want to share our expertise so that we all may mutually profit.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <a
                  class="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How to work with AS3ICS?
                </a>
              </h5>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">
                AS3ICS isn't like other companies, and it believes in a very
                personable relationship with its clients so that it can
                understand their needs and deliver a product that is 110% what
                was originally intended. If you would like to work with AS3ICS
                you can use the <b>Contact</b> button to send an email directly
                to the leadership of the company, or additionally there is a
                company phone that is looked at from time to time.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-leaders></app-leaders>
