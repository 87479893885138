<div id="myCarousel" class="carousel slide banner-2" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active"> <img class="first-slide img-fluid" src="assets/images/inner-banner.png" alt="First slide">
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Contact</h2>
          <p class="breadcrumb-item"><a href="#">Home</a> / Contact</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="contact-left">
          <h4>Submit Your Message</h4>
          <p>Established fact that a reader will be distracted by the readable content</p>
          <div class="form-cont">
            <form action="process.php" method="post" name="ContactForm" id="ContactForm" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="fname" placeholder="First Name *">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" class="form-control" name="lname" placeholder="Last Name *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="tel" class="form-control" name="phone" placeholder="Phone *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="email" class="form-control" name="email" placeholder="Email *">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea rows="5" class="form-control" name="comment" placeholder="Your Message *"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary wow tada">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="contact-right">
          <h4>Contact Info</h4>
          <p>Share Your Contact Info with Others</p>
          <div class="contact-info">
            <div class="row">
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
                  <div class="text-cont">
                    <h6>Address:</h6>
                    <p>P-32 Kolkata - 700125</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
                  <div class="text-cont">
                    <h6>Phone:</h6>
                    <p>+91 9874039154</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
                  <div class="text-cont">
                    <h6>Skype:</h6>
                    <p>my.skype.id</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-info-item">
                  <div class="icon-cont"><i class="icofont icofont-paint"></i></div>
                  <div class="text-cont">
                    <h6>Email ID:</h6>
                    <p>name@yourdomain.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
              	<div class="contact-map">
            	<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d58908.92750053163!2d88.4393756!3d22.6609473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1461641077502" class="map" allowfullscreen=""></iframe>
            </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>