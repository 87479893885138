import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServicesComponent } from './components/pages/services/services.component';

// import { BloglistComponent } from './components/pages/bloglist/bloglist.component';
// import { BlogdetailsComponent } from './components/pages/blogdetails/blogdetails.component';
// import { ContactComponent } from './components/pages/contact/contact.component';

// import { Home2Component } from './components/pages/_legacy/home2/home2.component';

const routes: Routes = [
  { path: '', component: ServicesComponent },
  { path: 'home', component: HomeComponent },
  //   { path: 'home2', component: Home2Component },
  { path: 'about', component: AboutComponent },
  { path: 'services', component: ServicesComponent },
  //   { path: 'bloglist', component: BloglistComponent },
  //   { path: 'blogdetails', component: BlogdetailsComponent },
  //   { path: 'contact', component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
