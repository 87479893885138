<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Notable Crypto & AI News</h2>
          <h6>
            The latest news on emerging AI and Crypto technologies you don't
            want to miss out on, curated by AS3ICS
          </h6>
        </div>
      </div>
    </div>

    <div class="coming-soon" style="margin-top: -30px; text-align: center">
      <h1>Coming Soon!</h1>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="blog-post-cont">
          <img src="assets/images/post-1.jpeg" class="img-fluid" alt="item 1" />
          <div class="text-cont">
            <h6><a href="#">Nilanjan</a> | <a href="#">March 2, 2023</a></h6>
            <h5><a href="#">What is Lorem Ipsum?</a></h5>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words
            </p>
            <a href="#" class="readdmore"
              >Read More <i class="icofont icofont-long-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="blog-post-cont">
          <img src="assets/images/post-2.jpeg" class="img-fluid" alt="item 1" />
          <div class="text-cont">
            <h6><a href="#">Nilanjan</a> | <a href="#">March 2, 2023</a></h6>
            <h5><a href="#">Where does it comes from?</a></h5>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words
            </p>
            <a href="#" class="readdmore"
              >Read More <i class="icofont icofont-long-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="blog-post-cont">
          <img src="assets/images/post-3.jpeg" class="img-fluid" alt="item 1" />
          <div class="text-cont">
            <h6><a href="#">Nilanjan</a> | <a href="#">March 2, 2023</a></h6>
            <h5><a href="#">What should we do now?</a></h5>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words
            </p>
            <a href="#" class="readdmore"
              >Read More <i class="icofont icofont-long-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscribe></app-subscribe>

<section class="company-history">
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <div class="company-history-txt">
          <h2>Who we are & What we do</h2>
          <p>
            AS3ICS is a small software and technology company that wants to
            bring its expertise in old and emerging technologies to small and
            large businesses, professionals, and big thinkers alike, whether it
            be optimizing & automating repetitive work processes, replacing
            costly SaaS (Software as a Service) products with cheaper in-house
            solutions, and bringing ideas to life from sources that may not have
            had the technical abilities to do so originally. We are engineers;
            we make things work. Lets work for you.
            <br />
            <br />
            There is no software project that is too large for AS3ICS, even
            though they are a small team. Having spent many years working on
            some very large projects, some almost entirely on ones own, AS3ICS
            has the experience to provide a <b>Full Stack</b> integrated
            consultations and solutions service for all businesses and
            professionals, small and large alike. We are a lean company; Don't
            overpay to a hipster software firm where you end up not even owning
            your code at the end of the day. Let AS3ICS treat you how you're
            meant to be treated, and get you the solutions you need.
          </p>
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <div class="company-history-txt">
          <h2>Our History</h2>
          <p>
            AS3ICS was founded on December 4, 2020, and has defied the global
            downturn and ventured to capitalize on its knowledge, experience,
            and expertise to chart a new path for itself where we are our
            leaders; we are our own bosses now. Integrity is a foundational
            principle at AS3ICS, and we vow to only make money in ways that are
            moral and respectable, and to act to prevent unjust and immoral
            practices wherever they may be.
          </p>
          <ul>
            <li>- Founded on December 4, 2020</li>
            <li>- Zach expanded and partnered with Kirk in early 2023</li>
            <li>
              - AI emerged as a new and innovative technology in 2023, forever
              changing how we work and the direction of AS3ICS
            </li>
            <li>
              - AS3ICS received its first external investors in early 2023,
              selling its first stock
            </li>
            <li>
              - Starting in 2023 a collection of projects is being put together
              using software that has already been written that will demonstrate
              AS3ICS abilities and creativity
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section style="margin-bottom: -130px">
  <section class="section-padding-ash">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <h2>Please Support Gadfly & G# Movement</h2>
          <br />
          <h5>
            Listen to our indie folk-rock album "We Shall Prevail", available on
            Apple Music, Spotify, Amazon Music, BandLab, SoundCloud, and many
            more streaming services, some including access to free compressed
            and uncompressed downloads that you can copy and paste as much as
            you fancy.
          </h5>
          <br />
          <iframe
            width="100%"
            height="600"
            src="https://www.bandlab.com/embed/collection/?id=96d69f3d-22e1-ed11-9f73-0022484437ba"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</section>
<section>
  <section class="section-padding-ash">
    <div class="container">
      <!-- <div class="row">
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/1.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/2.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/3.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/4.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/5.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/6.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
      </div> -->
    </div>
  </section>
</section>
