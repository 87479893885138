<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Meet the Leaders of AS3ICS</h2>
          <h6>
            Meet the leaders of our team of engineers and professionals who make
            AS3ICS effective at solving technical problems and delivering
            outstanding business services. Our experienced and dedicated team is
            committed to providing top-quality solutions to meet your needs. Let
            us help you take your business to the next level with our expertise
            and professionalism.
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="team-cont">
          <img
            src="assets/images/member-1.png"
            class="img-fluid"
            alt="item 1"
          />
          <div class="text-cont">
            <h5>
              <a
                href="https://www.linkedin.com/in/kirk-montana-b05b9a144/"
                target="_blank"
                >Kirk Montana</a
              >
            </h5>
            <h6>Lead Writer</h6>
            <p>
              With decades of experience writing content for a variety of
              situations, Kirk is a valuable asset to the AS3ICS team. He also
              brings his expertise in screenwriting for Hollywood, and is our
              resident expert in Web3, crypto, NFTs, and blockchain technology.
              His passion and dedication keep AS3ICS at the forefront of
              emerging technologies, and ensure that we deliver top-quality
              solutions to our clients.
            </p>
            <a
              href="https://www.linkedin.com/in/kirk-montana-b05b9a144/"
              class="readdmore"
              >Connect on LinkedIn
              <i class="icofont icofont-long-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="team-cont">
          <img
            src="assets/images/member-2.png"
            class="img-fluid"
            alt="item 1"
          />
          <div class="text-cont">
            <h5><a href="#">Zach DeGeorge</a></h5>
            <h6>Founder & CEO</h6>
            <p>
              Zach is the founder and leader of AS3ICS, bringing a hands-on
              approach to every project. With degrees in Electrical Engineering
              and Computer Engineering, he has spent his career as a Software
              Engineer and Full-Stack Software Developer, specializing in
              safety-critical components for major international automotive
              companies as well as web and app development.
            </p>
            <a
              href="https://www.linkedin.com/in/zachdegeorge/"
              class="readdmore"
              target="_blank"
              >Connect on LinkedIn
              <i class="icofont icofont-long-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="team-cont">
          <img
            src="assets/images/member-3.png"
            class="img-fluid"
            alt="item 1"
          />
          <div class="text-cont">
            <h5>
              <a href="https://www.linkedin.com/in/angelo-bertani-128807101/"
                >Angelo Bertani</a
              >
            </h5>
            <h6>Lead Programmer</h6>
            <p>
              Angelo is a college buddy of DeGeorge's, where they spent many
              hours together on the school's competitive robotics team. Angelo
              pulls double duty, as his daytime job is writing & integrating
              Ford's software for their hybrid vehicles. Besides the wealth of
              technical knowledge & experience Angelo brings to AS3ICS he is
              also a valuable insider to the automotive industry.
            </p>
            <a
              href="https://www.linkedin.com/in/angelo-bertani-128807101/"
              class="readdmore"
              >Connect on LinkedIn
              <i class="icofont icofont-long-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
