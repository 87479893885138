<div id="myCarousel" class="carousel slide banner-1" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <!-- <li data-target="#myCarousel" data-slide-to="2"></li> -->
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img
        class="first-slide"
        src="assets/images/banner-1.png"
        alt="First slide"
      />
      <div class="container">
        <div class="carousel-caption banner1 text-left">
          <h2 class="wow fadeInUp">Bring your ideas to reality</h2>
          <h6>
            Don't get held back by the technical details, let AS3ICS help you!
          </h6>
          <p class="hidden-sm-down">
            <a
              class="btn btn-md btn-default"
              href="mailto:contact@as3ics.com"
              role="button"
              >Contact</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <img
        class="second-slide"
        src="assets/images/banner-2.png"
        alt="Second slide"
      />
      <div class="container">
        <div class="carousel-caption banner1">
          <h2 class="wow fadeInUp">
            AS3ICS has the knowledge and experience in all things engineering,
            and many things not.
          </h2>
          <h6>
            The AS3ICS foundation draws upon its extensive and diverse
            background in software, electronics, and computer engineering, as
            well as its wealth of experience working with some of the world's
            largest international engineering companies. We have professional
            real-life writers who can generate content and campaigns for you as
            well!
          </h6>
          <p class="hidden-sm-down">
            <a
              class="btn btn-md btn-default"
              href="mailto:contact@as3ics.com"
              role="button"
              >Contact</a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="carousel-item">
      <img
        class="third-slide"
        src="assets/images/banner-3.png"
        alt="Third slide"
      />
      <div class="container">
        <div class="carousel-caption banner1 text-right">
          <h2 class="wow fadeInUp">We are AS3ICS</h2>
          <h6>
            AS3ICS believes in advocating for the inclusion of all individuals
            in a way that honors their personal comfort, identity, & beliefs,
            regardless of factors such as sex, race, sexual orientation, skin
            color, mental illness, or political and religious beliefs. We
            believe in Black Lives Matter. We stand with Ukraine.
          </h6>
        </div> 
      </div> 
    </div> -->
  </div>
  <a
    class="carousel-control-prev"
    href="#myCarousel"
    role="button"
    data-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="#myCarousel"
    role="button"
    data-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<section class="section-padding-ash">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>What can AS3ICS Can Do For You?</h2>
          <h6>
            AS3ICS has a broad range of services it can offer for clients of
            many sizes, from consultations to solutions.
          </h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-code-alt"></i></div>
          <div class="text-cont">
            <h6>Web Design & Development</h6>
            <p>
              Do you have a digital business idea, need help bringing your
              vision to life, or just want to upgrade your current website?
              AS3ICS is here to help you realize your dreams, whether you're a
              small or medium-sized business, an independent professional, or
              just have an idea. With AS3ICS you can own your code and avoid
              being held hostage like the practices at many other development
              companies.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-code"></i></div>
          <div class="text-cont">
            <h6>Web Hosting</h6>
            <p>
              Do you already have the code but don't know what to do next?
              AS3ICS can host your site and ensure it’s implemented correctly.
              With us, you only pay based on the cost of your hosting
              requirements, giving you peace of mind and reliable service. Let
              us help you take your code to the next level, and make sure your
              site is running smoothly and efficiently.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont">
            <i class="icofont icofont-dashboard-web"></i>
          </div>
          <div class="text-cont">
            <h6>Business Solutions</h6>
            <p>
              Is your business looking to improve efficiency by automating
              processes on AWS or building its own solution to reduce reliance
              on SaaS? AS3ICS can help turn your ideas into reality, allowing
              you to focus on your business while we take care of the technical
              details. Let us help you increase your efficiency, and make your
              business processes more streamlined and effective.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont">
            <i class="icofont icofont-presentation"></i>
          </div>
          <div class="text-cont">
            <h6>Consultations</h6>
            <p>
              Looking for advice from professionals with over a decade
              experience in programming, web development, or technology in
              general? AS3ICS offers a free 10-minute consultation to help you
              determine if we have the information you need before you commit to
              a paid consultation, and we additionally offer a free NDA upon
              request to ensure your confidentiality. Let us help you with your
              questions and provide you with expert advice.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-hard-disk"></i></div>
          <div class="text-cont">
            <h6>Web3 Consultations & Solutions</h6>
            <p>
              Don't get left behind in this rapidly changing world. AS3ICS can
              help you leverage blockchain and Web3 technologies to secure your
              information and stay ahead of the curve. Our team of experts can
              also advise you on emerging technologies and concepts to help you
              make informed decisions about your business. Let us help you stay
              on top of the latest trends and technologies, and secure your
              future success.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-beaker"></i></div>
          <div class="text-cont">
            <h6>Research & Development</h6>
            <p>
              Need help with research? AS3ICS has access to major and minor
              scientific journals, as well as a variety of resources to help
              solve any problem you may have. Let us help you with your research
              needs, and we'll ensure that you have access to the information
              you need to succeed.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont">
            <i class="icofont icofont-calculations"></i>
          </div>
          <div class="text-cont">
            <h6>Application Specific/Prototype Electronics</h6>
            <p>
              Do you need a proof-of-concept device or have a specialized
              electronics project? AS3ICS has the expertise to bring your ideas
              to life. Our team of experienced professionals can help you
              design, develop, and build custom electronics projects to meet
              your unique needs. Let us help you turn your vision into a
              reality.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont"><i class="icofont icofont-iphone"></i></div>
          <div class="text-cont">
            <h6>Forensic Reports/iCloud Digital Forensics</h6>
            <p>
              AS3ICS has extensive software experience, which makes us highly
              skilled in writing and attention to detail. We use this attention
              to detail, and experience, to provide forensic legal reports for
              cases of any size, drawing on our expertise to ensure that your
              reports are accurate and reliable. Let us help you with your legal
              reporting needs, and we'll ensure that you have the information
              you need to succeed.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
        <div class="services-cont">
          <div class="icon-cont">
            <i class="icofont icofont-binary"></i>
          </div>
          <div class="text-cont">
            <h6>Content Generation/Data Entry/Digitization</h6>
            <p>
              Do you need high-quality content for your website or project?
              AS3ICS can help! We specialize in writing content for various
              situations and can even digitize your existing information. Let us
              handle the tasks, and we'll make sure your content is
              professionally written and formatted to meet your needs.
            </p>
          </div>
        </div>
      </div>
      <div class="services-cont">
        <div class="icon-cont">
          <i class="icofont icofont-search-job"></i>
        </div>
        <div class="text-cont" style="padding-left: 20px">
          <h6>Don't See Exactly What You Need?</h6>
          <p>
            If what you are looking for is not found here don't forget to take
            advantage of your <b>free 10 minute consultation</b> to see if
            AS3ICS can still be of service for you.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-padding-about">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-lg-offset-right-6">
        <div class="text-left">
          <h2>Why Choose us</h2>
          <p>
            Choose us not because of all the technical things we can do or have
            done, but because we do it with honesty & integrity. AS3ICS will
            never try and take advantage of a client, lie about its
            capabilities, or take on a project it knows it can't follow through
            with. But it doesn't hurt that our brain-trust of experienced
            professionals & engineers have 10,000's of hours logged in their
            profession.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-lg-offset-right-6">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="counter-cont">
              <h4>
                <span
                  class="numscroller"
                  data-min="1"
                  data-max="10"
                  data-delay="2"
                  data-increment="2"
                  >10</span
                >+
              </h4>
              <h6>Years Experience</h6>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="counter-cont">
              <h4>
                <span
                  class="numscroller"
                  data-min="1"
                  data-max="10000"
                  data-delay="5"
                  data-increment="29"
                  >10,000</span
                >+
              </h4>
              <h6>Hours Programming</h6>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="counter-cont">
              <h4>
                <span
                  class="numscroller"
                  data-min="1"
                  data-max="3"
                  data-delay="2"
                  data-increment="1"
                  >3</span
                >+
              </h4>
              <h6>Engineering Domains</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscribe></app-subscribe>

<app-leaders></app-leaders>

<section class="section-padding">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="section-title text-center">
          <h2>Some of AS3ICS Previous Work</h2>
          <h6>
            Client privacy is of our utmost concern, but here are some projects
            our team has worked on that we can tell you about.
          </h6>
        </div>
      </div>
    </div>
    <div class="row" style="min-height: 500px">
      <div class="col-md-12">
        <div class="portfolioFilter text-center">
          <a href="#" data-filter="*" class="current">All Categories</a> /
          <a href="#" data-filter=".web">Web</a> /
          <a href="#" data-filter=".app">App</a> /
          <a href="#" data-filter=".rob">Robotics</a> /
          <a href="#" data-filter=".ece">Electronics</a>
        </div>
        <div class="portfolioContainer">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 rob">
              <div class="grid rob">
                <figure class="effect-lily">
                  <img
                    src="assets/images/portfolio/1.png"
                    class="img-fluid"
                    alt="Portfolio Picture 1"
                  />
                  <figcaption>
                    <h3>Autonomous Robot</h3>
                    <p>
                      From the International Ground Vehicle Competition, that
                      which paved the foundation for AS3ICS careers.
                    </p>
                    <p>
                      <a
                        class="info"
                        target="_blank"
                        href="https://youtu.be/teTl5adsZh4"
                        ><b>Watch Video</b></a
                      >
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 web app">
              <div class="grid web app">
                <figure class="effect-lily">
                  <img
                    src="assets/images/portfolio/2.png"
                    class="img-fluid"
                    alt="Portfolio Picture 2"
                  />
                  <figcaption>
                    <h3>Online Marketplace & Payments</h3>
                    <p>A demo of an online marketplace with payments.</p>
                    <p>
                      <a
                        class="info"
                        target="_blank"
                        href="https://youtu.be/3lV80OPE-c0"
                        ><b>Watch Video</b></a
                      >
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 app">
              <div class="grid app">
                <figure class="effect-lily">
                  <img
                    src="assets/images/portfolio/3.png"
                    class="img-fluid"
                    alt="Portfolio Picture 3"
                  />
                  <figcaption>
                    <h3>iOS Native App</h3>
                    <p>Demo of a native iOS app built by AS3ICS.</p>
                    <p>
                      <a
                        class="info"
                        target="_blank"
                        href="https://youtu.be/1glHBqCVGIg"
                        ><b>Watch Video</b></a
                      >
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 rob">
              <div class="grid rob">
                <figure class="effect-lily">
                  <img
                    src="assets/images/portfolio/4.png"
                    class="img-fluid"
                    alt="Portfolio Picture 4"
                  />
                  <figcaption>
                    <h3>Algorithm Development</h3>
                    <p>
                      We like to call this one "The robot bully". Custom
                      obstacle-avoidance algorithm.
                    </p>
                    <p>
                      <a
                        class="info"
                        target="_blank"
                        href="https://youtu.be/RjQQG1OBb44"
                        ><b>Watch Video</b></a
                      >
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 ece">
              <div class="grid ece">
                <figure class="effect-lily">
                  <img
                    src="assets/images/portfolio/5.png"
                    class="img-fluid"
                    alt="Portfolio Picture 5"
                  />
                  <figcaption>
                    <h3>MCUs & Electronics</h3>
                    <p>
                      One of DeGeorge's first Demo's of programming a
                      quad-copter simulator.
                    </p>
                    <p>
                      <a
                        class="info"
                        target="_blank"
                        href="https://youtu.be/lW2TmmLMvs8"
                        ><b>Watch Video</b></a
                      >
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 app">
              <div class="grid app">
                <figure class="effect-lily">
                  <img
                    src="assets/images/portfolio/6.png"
                    class="img-fluid"
                    alt="Portfolio Picture 6"
                  />
                  <figcaption>
                    <h3>More Native iOS...</h3>
                    <p>
                      Another look into the native iOS cloud based application .
                    </p>
                    <p>
                      <a
                        class="info"
                        target="_blank"
                        href="https://youtu.be/lW2TmmLMvs8"
                        ><b>Watch Video</b></a
                      >
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="testimonials">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="owl-carousel owl-theme testimonials-cont">
          <div class="item">
            <div class="testimonial-cont">
              <div class="testimonial-client-img aligncenter">
                <h2></h2>
              </div>

              <blockquote>
                At AS3ICS, we pride ourselves on our expertise in technology and
                problem-solving. While we may be a young and small team, our
                knowledge and experience speak for themselves. We approach every
                project with a dedication to delivering high-quality solutions
                that meet our clients' needs. Our focus is not on bragging, but
                on providing credible and reliable services that our clients can
                trust.
              </blockquote>
              <div class="testimonial-info">
                <div class="testimonial-client">
                  <h6>Zach DeGeorge</h6>
                  <p>Founder & CEO</p>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="testimonial-cont">
              <div class="testimonial-client-img aligncenter">
                <!-- <img
                  src="http://via.placeholder.com/80x80"
                  class="rounded-circle"
                  alt="t-2"
                /> -->
              </div>

              <blockquote>
                AS3ICS upholds a strict code of ethics, ensuring complete
                honesty and transparency with clients and only accepting
                projects that can be successfully completed.
              </blockquote>
              <div class="testimonial-info">
                <div class="testimonial-client">
                  <h6>Our Promise</h6>
                  <p>Since Always</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="item">
            <div class="testimonial-cont">
              <div class="testimonial-client-img aligncenter">
                <img
                  src="http://demos1.showcasedemos.in/jntuicem2017/html/v1/assets/images/kiara.jpg"
                  class="rounded-circle"
                  alt="t-2"
                />
              </div>

              <blockquote>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type
              </blockquote>
              <div class="testimonial-info">
                <div class="testimonial-client">
                  <h6>Alex Jhonson</h6>
                  <p>Designer</p>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <section class="section-padding-ash">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/1.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/2.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/3.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/4.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/5.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3">
          <div class="clients-cont">
            <img
              src="assets/images/logo/6.png"
              class="img-fluid"
              alt="item 1"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
