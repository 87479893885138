import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ApplicationRef,
} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home1',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent implements OnInit {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private applicationRef: ApplicationRef
  ) {}

  ngOnInit(): void {
    $.getScript('../assets/js/custom.js');

    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
      this.applicationRef.tick();
    }, 1000);
  }
}
