<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="footer-item">
          <img class="img-fluid" src="assets/images/logo-3.png" alt="logo-2" />
          <p>
            AS3ICS, Inc <br />
            2232 S Main St #149 <br />
            Ann Arbor, MI 48103 <br />
          </p>
          <p><a href="mailto:contact@as3ics.com">contact@as3ics.com</a></p>
          <p><a href="tel:+12024416542">+1(734)288-6304</a></p>
        </div>
        <div class="footer-item">
          <ul class="social">
            <li>
              <a href="https://fb.me/as3ics" target="_blank"
                ><i target="_blank" class="icofont icofont-social-facebook"></i
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/as3ics" target="_blank"
                ><i target="_blank" class="icofont icofont-social-twitter"></i
              ></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/as3ics/" target="_blank"
                ><i target="_blank" class="icofont icofont-social-linkedin"></i
              ></a>
            </li>
            <li>
              <a href="https://github.com/as3ics/" target="_blank"
                ><i target="_blank" class="icofont icofont-social-github"
                  >&nbsp;</i
                ></a
              >
            </li>
            <!-- <li>
              <a href="#"><i target="_blank" class="icofont icofont-social-pinterest"></i></a>
            </li> -->
            <!-- <li>
              <a href="#"><i target="_blank" class="icofont icofont-social-dribble"></i></a>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="col-lg-2 offset-lg-1">
        <div class="footer-item">
          <h4>Quick Links</h4>
          <ul>
            <li><a routerLink="/home">Home</a></li>
            <li><a routerLink="/about">About</a></li>
            <li><a routerLink="/services">Services</a></li>
            <li>
              <a href="https://ziggy.as3ics.com" target="_blank">Ziggy</a>
            </li>
            <li>
              <a href="https://encrypt.as3ics.com" target="_blank"
                >Ziggy Encrypt</a
              >
            </li>

            <!-- <li><a href="#">Blog</a></li>
            <li><a href="#">News</a></li>
            <li><a href="#">Contact</a></li> -->
          </ul>
          <li></li>
        </div>
      </div>
      <!-- <div class="col-lg-2">
        <div class="footer-item">
          <h4>Categories</h4>
          <ul>
            <li><a href="#">Web Design</a></li>
            <li><a href="#">Web Development</a></li>
            <li><a href="#">Online Marketing</a></li>
            <li><a href="#">App Design</a></li>
            <li><a href="#">App Development</a></li>
            <li><a href="#">Online Training</a></li>
          </ul>
        </div>
      </div> -->

      <div class="col-lg-3">
        <div class="footer-item">
          <h4>Support AS3ICS</h4>
          <!-- <ul>
            <li><a href="#">What is Lorem Ipsum?</a></li>
            <li><a href="#">Why do we use it?</a></li>
            <li><a href="#">There are many variations</a></li>
            <li><a href="#">passages of Lorem Ipsum</a></li>
            <li><a href="#">Contrary to popular</a></li>
            <li><a href="#">Lorem Ipsum is not simply</a></li>
          </ul> -->
          <ul>
            <li><a href="#">Make a donation today!</a></li>
            <br />
            <li>
              <b
                >Even if you don't need our services you can still help us by
                making a donation so that AS3ICS can spend more time developing
                and creating new apps, and less time hustling to scrape together
                rent money. Your donations and information are safe & secure, as
                the donations are processed through PayPal.</b
              >
              <br />
              <br />
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="EESSPRKJUB5A4"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="footer-bottom">
          <p>AS3ICS, Inc. © 2023. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- <div id="qnimate" class="off">
  <div id="search" class="open">
    <button data-widget="remove" id="removeClass" class="close" type="button">
      ×
    </button>
    <form action="#" method="post" autocomplete="off">
      <input
        type="text"
        placeholder="Type search keywords here"
        value=""
        name="term"
        id="term"
      />
      <button class="btn btn-primary btn-xl" type="submit">
        <span class="glyphicon glyphicon-search"></span> Search
      </button>
    </form>
  </div>
</div> -->
