import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
  @Input() action = '#';
  @Input() title = 'Subscribe to our Newsletter';
  @Input() body =
    'Stay up to date with the latest news and insights from AS3ICS to all things tech, blockchain, AI related, and whatever else happens to come across our minds.';

  constructor() {}

  ngOnInit(): void {}

  onSubmit($event) {
    alert('This feature coming soon!');
  }
}
