<nav class="navbar navbar-expand-md btco-hover-menu navbar-1">
  <div class="container">
    <a class="navbar-brand" routerLink="/home1"
      ><img
        src="assets/images/logo-1.png"
        class="img-fluid"
        alt="logo"
        style="width: 250px"
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse pl-5" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Apps
          </a>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                href="https://ziggy.as3ics.com"
                target="_blank"
                ><img
                  src="../../../../assets/images/ziggy.png"
                  width="100px;"
                  style="margin-left: -10px"
                />&nbsp;
                <span class="nav-dropdown-about">Markdown Journal</span></a
              >
            </li>
            <hr />
            <li>
              <a
                class="dropdown-item"
                href="https://encrypt.as3ics.com"
                target="_blank"
                ><span style="font-size: larger">Ziggy Encrypt</span>
                &nbsp;<span class="nav-dropdown-about">Secret Keeper</span></a
              >
            </li>
            <hr />
            <li>
              <a
                class="dropdown-item"
                href="https://gadfly.as3ics.com"
                target="_blank"
                ><img
                  src="../../../../assets/images/synesthesia.jpeg"
                  width="100px;"
                  style="
                    margin-left: -10px;
                    border-radius: 50%;
                    height: 50px;
                    width: 50px;
                  "
                />
                &nbsp;<span class="nav-dropdown-about"
                  ><b>We Shall Prevail</b> by Gadfly</span
                ></a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/about">About</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="/services">Services</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/home1">News</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/contact">Contact</a>
        </li> -->
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link btn btn-primary" href="mailto:contact@as3ics.com">
            Contact
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" id="addClass" href="#">
            <i class="icofont icofont-search-alt-1"></i>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
